import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useNavigationLabel } from 'utils/navigation'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'

import Layout from 'components/Layout'
import SlicesArea from 'components/SlicesArea'

const IS_BROWSER = typeof window !== 'undefined'

const ExperienceDetailPage = ({ location, data: staticData, pageContext }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const data = mergePrismicPreviewData({ staticData, previewData })
  const experienceDetailPage = data.allPrismicExperiences.edges[0].node.data

  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout
      heroImage={experienceDetailPage.hero}
      crumbs={crumbs}
      crumbLabel={useNavigationLabel(pageContext.uid)}
      metaTitle={experienceDetailPage.meta_title}
      metaDescription={experienceDetailPage.meta_description}
      pageTitle={experienceDetailPage.title}
      location={location}
    >
      <SlicesArea slicesData={experienceDetailPage.body} />
    </Layout>
  )
}

ExperienceDetailPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export const experienceDetailPageQuery = graphql`
  query experienceByUid($uid: String) {
    allPrismicExperiences(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            title
            summary_text
            meta_title
            meta_description
            hero {
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            summary_image {
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            body {
              ... on PrismicExperiencesBodyHeading {
                slice_type
                primary {
                  heading
                }
              }
              ... on PrismicExperiencesBodyContent {
                slice_type
                primary {
                  heading
                  rich_text {
                    html
                  }
                  button_colour
                  button_text
                  button {
                    uid
                    url
                    link_type
                  }
                }
              }
              ... on PrismicExperiencesBodyLargeImage {
                slice_type
                primary {
                  image_width
                  video_url
                  image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    alt
                  }
                }
              }
              ... on PrismicExperiencesBodyImageContent {
                slice_type
                primary {
                  button {
                    uid
                    url
                    link_type
                  }
                  button_colour
                  button_text1
                  heading
                  layout
                  image_overlay_text
                  image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  video_url
                  rich_text {
                    html
                  }
                }
              }
              ... on PrismicExperiencesBodyLinkPanel {
                slice_type
                items {
                  panel_title
                  panel_link {
                    uid
                    url
                    link_type
                  }
                  panel_image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicExperiencesBodyTwoImage {
                slice_type
                primary {
                  image_1 {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  image_2 {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicExperiencesBodyLocations {
                slice_type
                items {
                  activity_location {
                    longitude
                    latitude
                  }
                  activity_name
                }
                primary {
                  description
                  title1
                }
              }
              ... on PrismicExperiencesBodyAppInformation {
                slice_type
                primary {
                  layout
                  heading
                  button_text
                  button {
                    uid
                    url
                    link_type
                  }
                  image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    alt
                  }
                  rich_text {
                    html
                  }
                }
                items {
                  feature_description
                  feature_icon {
                    localFile {
                      url
                    }
                  }
                }
              }
              ... on PrismicExperiencesBodyAccordion {
                slice_type
                primary {
                  accordion_heading
                }
                items {
                  accordion_item_title
                  accordion_item_content {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ExperienceDetailPage
